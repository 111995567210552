<template>
  <div class="container">
    <div ref="box">
      <div ref="header">
        <a-page-header
          style="border-bottom: 1px solid rgb(235, 237, 240)"
          title="任务参加管理"
        >
        </a-page-header>
      </div>
      <div ref="form">
        <a-form
          :form="form"
          layout="inline"
          @submit="handleSubmit"
          style="padding: 15px 0"
          ref="form"
        >
          <a-form-item label="用户手机号">
            <a-input
              v-decorator="['mobile', { rules: [{ required: false }] }]"
            />
          </a-form-item>
          <a-form-item label="活动名称">
            <a-select
              placeholder="全部"
              style="width: 180px"
              v-decorator="['activity_id', { rules: [{ required: false }] }]"
            >
              <a-select-option value=""> 全部 </a-select-option>
              <a-select-option
                v-for="item in activityList"
                :key="item.activity_id"
                :value="item.activity_id"
              >
                {{ item.title }}
              </a-select-option>
            </a-select> </a-form-item
          ><a-form-item label="活动分类">
            <a-select
              placeholder="全部"
              style="width: 180px"
              v-decorator="['activity_class', { rules: [{ required: false }] }]"
            >
              <a-select-option value=""> 全部 </a-select-option>
              <a-select-option value="jd"> 京东 </a-select-option>
              <a-select-option value="tb"> 淘宝 </a-select-option>
              <a-select-option value="video_clip">视频剪辑</a-select-option>
              <a-select-option value="task">任务</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="口令">
            <a-input v-decorator="['code', { rules: [{ required: false }] }]" />
          </a-form-item>
          <a-form-item label="口令状态">
            <a-select
              placeholder="全部"
              style="width: 180px"
              v-decorator="['status', { rules: [{ required: false }] }]"
            >
              <a-select-option value=""> 全部 </a-select-option>
              <a-select-option value="1"> 待审核 </a-select-option>
              <a-select-option value="2"> 通过 </a-select-option>
              <a-select-option value="3">不通过</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="getExport(0)">
              搜索
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table
        :columns="columns"
        :data-source="commandList"
        :pagination="false"
        :scroll="{ x: 1400, y: table_h }"
        :row-key="(record) => record.share_code_id"
        :expandIcon="expandIcon"
        size="middle"
        ref="tableList"
      >
        <span slot="activity_class" slot-scope="activity_class">
          <span v-if="activity_class == 'jd'">京东</span>
          <span v-if="activity_class == 'tb'">淘宝</span>
          <span v-if="activity_class == 'video_clip'">视频剪辑</span>
          <span v-if="activity_class == 'task'">任务</span>
        </span>
        <span
          slot="video_clip"
          slot-scope="video_clip, num"
          v-if="video_clip.video_clip.length != 0"
        >
          <button class="ant-btn" size="small" @click="videoclip(num)">
            查看（{{ video_clip.video_clip.length }}个）
          </button>
          <!-- <span v-for="(item, key, index) in video_clip" :key="index">
            <span v-if="key == 'video_clip'">
              <span v-for="(item1, key1, index1) in item" :key="index1"> -->
          <!-- <a-tooltip placement="left" :title="item1.url"
                ><span >{{item1.title}}</span></a-tooltip
              > -->
          <!-- <a-popconfirm
    :title="video_clip.video_clip"
    ok-text="Yes"
    @confirm="confirm"
    @cancel="cancel"
  >
    <a-icon slot="icon" type="" style="width:0" />
    <span >查看</span>
  </a-popconfirm>
                  <br/> -->
          <!-- </span>
            </span>
          </span> -->
        </span>
        <span slot="platform_info" slot-scope="platform_info">
          <span v-for="(item, index) in platform_info" :key="item">
            <span v-if="index != 0">,</span>
            <span>{{ item.platform_class.platform_name }}</span>
          </span>
        </span>
        <span slot="code" slot-scope="code, num">
          <span>
            <span @click="changeCode(num)">{{ code }}</span>
            <a-icon
              type="edit"
              style="color: #1890ff; padding-left: 5px"
              @click="changeCode(num)"
            />
          </span>
        </span>
        <span
          slot="reward_amount"
          slot-scope="reward_amount, num"
          v-if="num.status == 2"
        >
          <span>
            <span @click="changeRewardAmount(num)">{{ reward_amount }}</span>
            <a-icon
              type="edit"
              style="color: #1890ff; padding-left: 5px"
              @click="changeRewardAmount(num)"
            />
          </span>
        </span>
        <span slot="status" slot-scope="status">
          <span v-if="status == 1">待审核</span>
          <span v-if="status == 2">通过</span>
          <span v-if="status == 3">不通过</span>
        </span>
        <span slot="btns" slot-scope="btns, num">
          <a-button
            class="editable-add-btn"
            v-for="btn in btns"
            :key="btn"
            @click="handel(btn, num)"
            size="small"
            style="margin: 5px"
          >
            {{ btn }}
          </a-button>
        </span>
        <a-table
          slot="expandedRowRender"
          slot-scope="num"
          :columns="innerColumns"
          :data-source="num.platform_info"
          :scroll="{ y: 400 }"
          :pagination="false"
          :row-key="(record) => record.id"
        >
          <img
            slot="avatar"
            slot-scope="avatar"
            :src="avatar"
            alt=""
            style="width: 60px; height: 60px; border-radius: 50%"
          />

          <img
            slot="screenshot"
            slot-scope="screenshot"
            :src="screenshot"
            alt=""
            v-image-preview
            style="width: 60px"
          />
        </a-table>
      </a-table>
      <div ref="pagination">
        <a-pagination
          v-if="pageshow"
          :default-current="currentPage"
          :defaultPageSize="defaultPageSize"
          :page-size-options="pageSizeOptions"
          show-size-changer
          show-quick-jumper
          :total="total_num"
          :show-total="(total) => `共 ${total} 条`"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        >
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal
      v-model="visible"
      :title="modelTitle"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      ok-text="确认"
      cancel-text="取消"
    >
      <a-form :form="addform" @submit="handleSubmit">
        <a-form-item label="拒绝原因：">
          <a-input
            v-decorator="[
              'denial_reason',
              {
                rules: [{ required: true, message: '请输入拒绝原因：!' }],
              },
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      v-model="visibleCode"
      :title="modelTitle"
      :confirm-loading="confirmLoading"
      ok-text="确定"
      @ok="handleCodeOk"
      cancel-text="取消"
    >
      <a-form :form="Codeform" @submit="handleSubmit">
        <a-form-item
          label="当前口令："
          style="display: flex; justify-content: flex-start; flex-dirction: row"
        >
          <span>{{ code }}</span>
        </a-form-item>
        <a-form-item label="修改口令：">
          <a-input
            placeholder="请输入口令"
            v-decorator="[
              'code',
              {
                rules: [{ required: true, message: '请输入口令!' }],
              },
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      v-model="visibleAmount"
      :title="modelTitle"
      :confirm-loading="confirmLoading"
      ok-text="确定"
      @ok="handleAmountOk"
      cancel-text="取消"
    >
      <a-form :form="Amountform" @submit="handleSubmit">
        <!-- <a-form-item
          label="当前口令："
          style="display: flex; justify-content: flex-start; flex-dirction: row"
        >
          <span>{{ code }}</span>
        </a-form-item> -->
        <a-form-item label="金额：">
          <a-input-number
            step="0.01"
            :min="0.01"
            v-decorator="[
              'amount',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入金额!',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="类型">
          <a-radio-group
            v-decorator="[
              'pm',
              {
                rules: [{ required: true, message: '请选择类型!' }],
              },
            ]"
          >
            <a-radio :value="1"> 增加 </a-radio>
            <a-radio :value="0"> 减少 </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="是否分佣">
          <a-radio-group
            v-decorator="[
              'is_allocation_fee',
              {
                rules: [{ required: true, message: '请选择类型!' }],
              },
            ]"
          >
            <a-radio :value="1"> 是 </a-radio>
            <a-radio :value="0"> 否 </a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-modal>

    <a-modal
      v-model="videovisible"
      :title="modelTitle"
      ok-text="确定"
      cancel-text="取消"
    >
      <div>
        <a-descriptions bordered>
          <a-descriptions-item
            v-for="(value, key) in videoclipList"
            :key="key"
            :label="value.title"
            :span="3"
          >
            {{ value.url }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "Car",
  data() {
    return {
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      export: 0,
      addform: this.$form.createForm(this, { name: "city_add" }),
      Codeform: this.$form.createForm(this, { name: "Codeform" }),
      Amountform: this.$form.createForm(this, { name: "Amountform" }),
      columns: [
        {
          title: "活动审核ID",
          key: "share_code_id",
          dataIndex: "share_code_id",
          align: "center",
        },
        {
          title: "用户昵称",
          key: "users_nickname",
          dataIndex: "users_nickname",
          align: "center",
        },
        {
          title: "用户手机号",
          key: "users_mobile",
          dataIndex: "users_mobile",
          align: "center",
        },
        {
          title: "口令内容",
          key: "code",
          dataIndex: "code",
          align: "center",
          scopedSlots: { customRender: "code" },
        },
        {
          title: "申请平台",
          key: "platform_info",
          dataIndex: "platform_info",
          align: "center",
          scopedSlots: { customRender: "platform_info" },
        },
        {
          title: "活动名称",
          key: "activity_title",
          dataIndex: "activity_title",
          align: "center",
        },
        {
          title: "活动分类",
          key: "activity_class",
          dataIndex: "activity_class",
          align: "center",
          scopedSlots: { customRender: "activity_class" },
        },
        {
          title: "任务作业",
          key: "value",
          dataIndex: "value",
          align: "center",
          scopedSlots: { customRender: "video_clip" },
          width: 200,
        },
        {
          title: "翻红奖励(元)",
          key: "reward_amount",
          dataIndex: "reward_amount",
          scopedSlots: { customRender: "reward_amount" },
          align: "center",
        },
        {
          title: "审核状态",
          key: "status",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
          align: "center",
        },
        {
          title: "操作",
          key: "btns",
          dataIndex: "btns",
          scopedSlots: { customRender: "btns" },
          align: "center",
          width: 100,
        },
      ],
      innerColumns: [
        {
          title: "平台名称",
          key: "platform_class.platform_name",
          dataIndex: "platform_class.platform_name",
          align: "center",
        },
        {
          title: "昵称",
          key: "info.nickname",
          dataIndex: "info.nickname",
          align: "center",
        },
        {
          title: "头像",
          key: "info.avatar",
          dataIndex: "info.avatar",
          scopedSlots: { customRender: "avatar" },
          align: "center",
        },
        {
          title: "粉丝数",
          key: "info.fans_count",
          dataIndex: "info.fans_count",
          align: "center",
        },
        {
          title: "截图",
          key: "info.screenshot",
          dataIndex: "info.screenshot",
          scopedSlots: { customRender: "screenshot" },
          align: "center",
        },
      ],
      visible: false,
      modelTitle: "",
      confirmLoading: false,
      fileList: [],
      uploadBtn: true,
      pageshow: false,
      currentPage: 1,
      total_num: 0,
      defaultPageSize: 20,
      pageSizeOptions: ["10", "20", "30", "50", "100"],
      table_h: "",
      commandList: [],
      visibleCode: false,
      code: "",
      visibleAmount: false,
      activityList: [],
      videoclipList: "",
      videovisible: false,
    };
  },
  components: {},
  mounted() {
    window.addEventListener("resize", () => this.changeTableH(), true);
    this.getSearchList({});
    this.getActivityList();
  },
  methods: {
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight -
        this.$refs.header.offsetHeight -
        this.$refs.form.offsetHeight -
        this.$refs.pagination.offsetHeight -
        45;
      if (this.table_h <= 250) {
        this.table_h = 400;
      }
    },
    videoclip(num) {
      this.modelTitle = "查看任务作业";
      this.videovisible = true;
      this.videoclipList = num.value.video_clip;
    },
    expandIcon(props) {
      if (props.expanded) {
        return (
          <a
            style="color: 'black',margin-right:0px"
            onClick={(e) => {
              props.onExpand(props.record, e);
            }}
          >
            <a-icon type="caret-down" />{" "}
          </a>
        );
      } else {
        return (
          <a
            style="color: 'black' ,margin-right:0px"
            onClick={(e) => {
              props.onExpand(props.record, e);
            }}
          >
            <a-icon type="caret-right" />
          </a>
        );
      }
    },
    //修改口令
    changeCode(num) {
      this.code = num.code;
      this.share_code_id = num.share_code_id;
      this.visibleCode = true;
      this.modelTitle = "修改口令内容";
      this.confirmLoading = false;
      this.$nextTick(() => {
        this.Codeform.setFieldsValue({
          code: "",
        });
      });
    },
    handleCodeOk() {
      var that = this;
      that.confirmLoading = true;
      that.Codeform.validateFields((error, values) => {
        if (!error) {
          var data = values;
          data.share_code_id = that.share_code_id;
          that.axios.post("/admin/ShareCode/codeUpdate", data).then((res) => {
            that.$message.loading({
              content: "加载中..",
              key: "load",
              duration: 0.00001,
            });
            if (that.$code(res)) {
              that.$message.success("口令内容修改成功");
              that.form.validateFields((error, values) => {
                that.getSearchList(values);
              });
            }

            that.visibleCode = false;
            that.confirmLoading = false;
          });
        } else {
          that.confirmLoading = false;
        }
      });
    },
    //修改奖励金额
    changeRewardAmount(num) {
      this.share_code_id = num.share_code_id;
      this.visibleAmount = true;
      this.modelTitle = "修改奖励金额";
      this.confirmLoading = false;
      this.$nextTick(() => {
        this.Amountform.setFieldsValue({
          amount: "",
          pm: "",
        });
      });
    },
    handleAmountOk() {
      var that = this;
      that.confirmLoading = true;
      that.Amountform.validateFields((error, values) => {
        if (!error) {
          var data = values;
          that.axios
            .post(
              "/admin/activityTask/setRewardAmount/" + that.share_code_id,
              data
            )
            .then((res) => {
              that.$message.loading({
                content: "加载中..",
                key: "load",
                duration: 0.00001,
              });
              if (that.$code(res)) {
                that.$message.success("奖励金额修改成功");
                that.form.validateFields((error, values) => {
                  that.getSearchList(values);
                });
              }

              that.visibleAmount = false;
              that.confirmLoading = false;
            });
        } else {
          that.confirmLoading = false;
        }
      });
    },
    //获取改变后的页码
    onPageChange(pageNumber) {
      var that = this;
      this.currentPage = pageNumber;
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取每页条数
    onShowSizeChange(current, pageSize) {
      var that = this;
      this.defaultPageSize = pageSize;
      if (that.currentPage * pageSize - pageSize + 1 >= that.total_num) {
        that.currentPage = 1;
      }
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取列表
    getSearchList(data) {
      var that = this;
      data.limit = that.defaultPageSize;
      data.page = that.currentPage;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.pageshow = false;
      that.axios
        .get("/admin/ShareCode/list", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            var commandList = res.data.data;
            for (var i = 0; i < commandList.length; i++) {
              if (commandList[i].status == 1) {
                commandList[i].btns = ["通过", "不通过"];
              }
            }
            that.changeTableH();
            that.commandList = commandList;
            that.total_num = res.data.count;
            that.currentPage = res.data.currentPage;
          }
        });

      that.$nextTick(() => {
        that.pageshow = true;
      });
    },
    //获取活动列表
    getActivityList() {
      var that = this;
      that.axios.get("/admin/ShareCode/list/activitySelect").then((res) => {
        if (that.$code(res)) {
          that.activityList = res.data.data;
        }
      });
    },
    // 操作
    handel(btn, num) {
      var that = this;
      that.share_code_id = num.share_code_id;
      if (btn == "通过") {
        var data = {};
        data.share_code_id = num.share_code_id;
        data.status = 2;
        that.status = 2;
        that.$confirm({
          title: "提示",
          content: "口令审核状态：" + btn,
          okText: "确认",
          cancelText: "取消",
          onOk() {
            that.$message.loading({
              content: "加载中..",
              key: "load",
              duration: 0,
            });
            that.axios
              .get("/admin/ShareCode/review", { params: data })
              .then((res) => {
                that.$message.loading({
                  content: "加载中..",
                  key: "load",
                  duration: 0.00001,
                });
                if (that.$code(res)) {
                  that.$message.success("审核成功");
                  that.form.validateFields((error, values) => {
                    that.getSearchList(values);
                  });
                }
              });
          },
        });
      }
      if (btn == "不通过") {
        that.status = 3;
        that.visible = true;
        that.modelTitle = "拒绝原因";

        that.confirmLoading = false;
        that.$nextTick(() => {
          that.addform.setFieldsValue({
            denial_reason: "",
          });
        });
      }
    },
    //拒绝原因
    handleOk() {
      var that = this;
      that.confirmLoading = true;
      that.addform.validateFields((error, values) => {
        if (!error) {
          var data = values;
          data.share_code_id = that.share_code_id;
          data.status = that.status;
          that.axios
            .get("/admin/ShareCode/review", { params: data })
            .then((res) => {
              that.$message.loading({
                content: "加载中..",
                key: "load",
                duration: 0.00001,
              });
              if (that.$code(res)) {
                that.$message.success("已审核");
                that.form.validateFields((error, values) => {
                  that.getSearchList(values);
                });
              }
              that.visible = false;
              that.confirmLoading = false;
            });
        } else {
          that.confirmLoading = false;
        }
      });
      // this.visible = false;
    },
    handleSubmit(e) {
      if (e) {
        e.preventDefault();
      }
      var that = this;
      that.form.validateFields((error, values) => {
        if (that.export == 0) {
          that.currentPage = 1;
          // this.$refs.pagination.internalCurrentPage = this.nowpage;
          that.getSearchList(values);
        } else {
          var data = "";
          for (var key in values) {
            if (values[key]) {
              data += "&" + key + "=" + values[key];
            }
          }
          var myObj = {
            method: "get",
            url: "/" + that.pageNowUrl + "?export=1" + data,
            fileName: "设备列表.csv",
          };
          this.$exportMethod(myObj);
        }
      });
    },
  },
};
</script>
<style scoped>
table img {
  width: 100px;
}
.ant-table-wrapper {
  flex: 1;
}
/deep/.ant-input-number {
  width: 100%;
}
/deep/.ant-popover-message-title {
  padding-left: 0;
}
</style>